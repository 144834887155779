import $ from "jquery";

document.addEventListener('DOMContentLoaded', function(){

    // cache the navigation links
    var $navigationLinks = $('.category-element');

    // cache (in reversed order) the sections
    var $sections = $($(".category-container-viewport-element").get().reverse());




    // map each section id to their corresponding navigation link
    var sectionIdTonavigationLink = {};
    $sections.each(function() {
        var id = $(this).data('category-target-id');
        sectionIdTonavigationLink[id] = $('.category-selector-id-' + id);
    });

    // throttle function, enforces a minimum time interval
    function throttle(fn, interval) {
        var lastCall, timeoutId;
        return function () {
            var now = new Date().getTime();
            if (lastCall && now < (lastCall + interval) ) {
                // if we are inside the interval we wait
                clearTimeout(timeoutId);
                timeoutId = setTimeout(function () {
                    lastCall = now;
                    fn.call();
                }, interval - (now - lastCall) );
            } else {
                // otherwise, we directly call the function
                lastCall = now;
                fn.call();
            }
        };
    }

    function getActiveImageName(name) {
        return name.replace(".png", "_aktiv.png")
    }

    function getInactiveImageName(name) {
        return name.replace("_aktiv.png", ".png")
    }


    function highlightNavigation() {

        // get the current vertical position of the scroll bar
        var scrollPosition = $(window).scrollTop();



        var arrVisible = []
        var sectionCounter = -1
        var lastElement = 0

        // iterate the sections
        $sections.each(function() {
            sectionCounter++

            var currentSection = $(this);


            // get the position of the section
            var sectionTop = currentSection.offset().top;


            var scrollPositionUpperThreeTenth = scrollPosition+( (window.innerHeight/10) *3)
            if (scrollPositionUpperThreeTenth < 0) {
                scrollPositionUpperThreeTenth = 0
            }



            //check if first section is in the first third of the window
            if (sectionCounter == lastElement) {


                var scrollPositionLowerTwoTenth = scrollPosition+( (window.innerHeight/10) *8)
                if (scrollPositionLowerTwoTenth < 0) {
                    scrollPositionLowerTwoTenth = 0
                }


                if ( scrollPositionLowerTwoTenth >= sectionTop ) {
                    // get the section id
                    var id = currentSection.data('category-target-id');

                    // get the corresponding navigation link
                    var $navigationLink = sectionIdTonavigationLink[id];
                    // if the link is not active
                    if (!$navigationLink.find('.category-title').hasClass('active')) {
                        // remove .active class from all the links
                        $navigationLinks.find('img').each(function(){
                            $(this).attr('src', getInactiveImageName($(this).attr('src')))
                        });
                        $navigationLinks.find('.category-title').removeClass('active');

                        // add .active class to the current link
                        $navigationLink.find('img').attr('src',  getActiveImageName($navigationLink.find('img').attr('src')) )
                        $navigationLink.find('.category-title').addClass('active');

                    }
                    // we have found our section, so we return false to exit the each loop
                    return false;
                }


            }





            // if the user has scrolled over the top of the section
            if (scrollPositionUpperThreeTenth >= sectionTop) {
                // get the section id
                var id = currentSection.data('category-target-id');

                // get the corresponding navigation link
                var $navigationLink = sectionIdTonavigationLink[id];
                // if the link is not active
                if (!$navigationLink.find('.category-title').hasClass('active')) {
                    // remove .active class from all the links
                    $navigationLinks.find('.category-title').removeClass('active');
                    $navigationLinks.find('img').each(function(){
                        $(this).attr('src', getInactiveImageName($(this).attr('src')))
                    });

                    // add .active class to the current link
                    $navigationLink.find('.category-title').addClass('active');
                    $navigationLink.find('img').attr('src',  getActiveImageName($navigationLink.find('img').attr('src')) )






                }
                // we have found our section, so we return false to exit the each loop
                return false;
            }
        });

    }

    $(window).scroll( throttle(highlightNavigation,100) );


    setTimeout(function(){
        $(window).trigger("scroll")
    }, 100)

    // if you don't want to throttle the function use this instead:
     //$(window).scroll( highlightNavigation );
});

