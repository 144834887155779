import {getIdFromAttributeName} from './helpers.js'
import $ from "jquery";
import {Collapse} from "bootstrap";

document.addEventListener('DOMContentLoaded', function(){

    let objCollapseElements = $('[id^=collapse-faq-]')
    objCollapseElements.each(function() {
        let jqueryObjTarget = $(this)
        let targetId = $(this).attr('id')

        //targetId = getIdFromAttributeName(targetId)


        let objTarget = document.getElementById(targetId)

        objTarget.addEventListener('show.bs.collapse', function () {
            jqueryObjTarget.parent().find(".arrow").addClass("down")
            jqueryObjTarget.parent().find(".arrow").removeClass("right")
            $(window).trigger("scroll")
        })

        objTarget.addEventListener('hide.bs.collapse', function () {
            jqueryObjTarget.parent().find(".arrow").addClass("right")
            jqueryObjTarget.parent().find(".arrow").removeClass("down")
            $(window).trigger("scroll")
        })
    })


    let objCollapseMobileElements = $('[id^=collapse-mobile-faq-]')
    objCollapseMobileElements.each(function() {
        let jqueryObjTarget = $(this)
        let targetId = $(this).attr('id')

        let objTarget = document.getElementById(targetId)

        objTarget.addEventListener('show.bs.collapse', function () {
            jqueryObjTarget.parent().find(".arrow").addClass("down")
            jqueryObjTarget.parent().find(".arrow").removeClass("right")
            $(window).trigger("scroll")
        })

        objTarget.addEventListener('hide.bs.collapse', function () {
            jqueryObjTarget.parent().find(".arrow").addClass("right")
            jqueryObjTarget.parent().find(".arrow").removeClass("down")
            $(window).trigger("scroll")
        })
    })





});



