import $ from "jquery";
import modal from    'bootstrap';

document.addEventListener('DOMContentLoaded', function(){

    // var privacyModal = document.getElementById('privacyModal')
    // let privacyLinks = document.querySelectorAll('.privacy-link')

    let acceptedPrivacyModalButton = document.getElementById('acceptAndCloseModal')

    let privacyCheckbox = document.getElementById('pme_application_privacy_confirmed')

    // privacyLinks.forEach(function(elem) {
    //     elem.addEventListener("click", function() {
    //         privacyModal.modal('show')
    //     });
    // });

    acceptedPrivacyModalButton.addEventListener("click", function() {
       // privacy accepted -> check box
        privacyCheckbox.checked = true
    });





});